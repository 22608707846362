<template>
  <app-page :toolbar="toolbar" :list="list" @loaded="onLoaded">
    <template #append>
      <el-alert :title="balanceTitle" type="success" :closable="false" />
    </template>
  </app-page>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'Stats',
  data() {
    return {
      toolbar: {
        size: 'small',
        labelWidth: '80px',
        igrone: ['canteenType'],
        model: {
          name: undefined,
          departmentId: undefined,
          telephone: undefined,
          policeCard: undefined,
          consumerGroupId: undefined,
          canteenType: Number(Number(sessionStorage.getItem('canteenType')) !== 999 ? sessionStorage.getItem('canteenType') : 1),
          startDate: dayjs()
            .startOf('month')
            .format('YYYY-MM-DD'),
          endDate: dayjs()
            .endOf('month')
            .format('YYYY-MM-DD'),
          type: undefined,
          operateUserId: undefined
        },
        fields: [
          {
            label: '姓名',
            prop: 'name',
            placeholder: '请输入要搜索的姓名',
            clearable: true
          },
          {
            label: '部门',
            prop: 'departmentId',
            placeholder: '请选择要搜索的部门',
            type: 'cascader',
            clearable: true,
            collapseTags: true,
            filterable: true,
            props: {
              expandTrigger: 'hover',
              multiple: true,
              label: 'name',
              value: 'id'
            },
            options: [],
            url: '/department/getList',
            method: 'post'
          },
          {
            label: '电话',
            prop: 'telephone',
            placeholder: '请输入要搜索的电话',
            clearable: true
          },
          {
            label: '警号',
            prop: 'policeCard',
            placeholder: '请输入要搜索警号',
            clearable: true
          },
          {
            label: '分组',
            prop: 'consumerGroupId',
            placeholder: '请选择要搜索分组',
            type: 'select',
            clearable: true,
            options: [],
            url: '/canteenConsumerGroup/dropDownList',
            mapper(item) {
              return {
                label: item.name,
                value: item.id
              };
            }
          },
          {
            label: '食堂',
            prop: 'canteenType',
            placeholder: '请选择要搜索食堂',
            type: 'select',
            // clearable: true,
            options: [
              { label: '全部', value: undefined },
              { label: '一食堂', value: 1 },
              { label: '二食堂', value: 2 }
            ]
          },
          {
            label: '统计日期',
            prop1: 'startDate',
            prop2: 'endDate',
            type: 'daterange',
            valueFormat: 'yyyy-MM-dd',
            format: 'yyyy-MM-dd',
            startPlaceholder: '开始日期',
            endPlaceholder: '结束日期',
            pickerOptions: {
              firstDayOfWeek: 1
            }
          },
          {
            label: '类型',
            prop: 'type',
            placeholder: '请选择要搜索的类型',
            type: 'select',
            clearable: true,
            options: [
              { label: '全部', value: undefined },
              { label: '现金扣费', value: 0 },
              { label: '补贴扣费', value: 1 },
              { label: '提现扣费', value: 2 }
            ]
          },
          {
            label: '操作人',
            prop: 'operateUserId',
            type: 'select',
            clearable: true,
            options: [],
            url: '/admin-user/getList',
            method: 'post',
            data: {
              startPage: 1,
              pageSize: 500,
              isReg: 1
            },
            mapper(item) {
              return {
                label: item.name,
                value: item.userid
              };
            },
            returnKey: 'records'
          }
        ],
        downloader: {
          text: '导出',
          url: '/canteenManualDeductionLog/manualDeductionStatisticsExport'
        }
      },
      list: {
        mode: 'all',
        url: '/canteenManualDeductionLog/manualDeductionStatistics',
        method: 'post',
        fields: [
          { label: '日期', prop: 'date', width: 360, headerAlign: 'center' },
          {
            label: '补贴扣费',
            prop: 'subsidyTotal',
            width: 180,
            align: 'right',
            headerAlign: 'center',
            formatter(row) {
              return `￥${row.subsidyTotal}`;
            }
          },
          {
            label: '现金扣费',
            prop: 'cashTotal',
            width: 180,
            align: 'right',
            headerAlign: 'center',
            formatter(row) {
              return `￥${row.cashTotal}`;
            }
          },
          {
            label: '提现扣费',
            prop: 'withdrawTotal',
            width: 180,
            align: 'right',
            headerAlign: 'center',
            formatter(row) {
              return `￥${row.withdrawTotal}`;
            }
          },
          {
            label: '扣费总计',
            prop: 'total',
            width: 160,
            align: 'right',
            headerAlign: 'center',
            formatter(row) {
              return `￥${row.total}`;
            }
          }
        ],
        action: false,
        pages: false
      },
      balance: {
        sumCashTotal: 0,
        sumSubsidyTotal: 0,
        sumTotal: 0,
        sumWithdrawTotal: 0
      }
    };
  },
  computed: {
    // 汇总标题
    balanceTitle() {
      const { sumSubsidyTotal, sumCashTotal, sumWithdrawTotal, sumTotal } = this.balance;
      return `补贴扣费总计:￥${sumSubsidyTotal}，现金扣费总计:￥${sumCashTotal}，提现扣费总计:${sumWithdrawTotal}，总计:￥${sumTotal}`;
    }
  },
  methods: {
    onLoaded(e) {

      if (sessionStorage.getItem('canteenType')){
        if (Number(sessionStorage.getItem('canteenType')) === 1){
          this.toolbar.fields[5].options = [{ label: '一食堂', value: 1 }]
        }else if (Number(sessionStorage.getItem('canteenType')) === 2){
          this.toolbar.fields[5].options = [{ label: '二食堂', value: 2 }]
        }
      }

      this.balance.sumCashTotal = e.other.sumCashTotal;
      this.balance.sumSubsidyTotal = e.other.sumSubsidyTotal;
      this.balance.sumTotal = e.other.sumTotal;
      this.balance.sumWithdrawTotal = e.other.sumWithdrawTotal;
    }
  }
};
</script>
