<template>
  <app-page :toolbar="toolbar" :list="list" @loaded="onLoaded">
    <template #append>
      <el-alert :title="balanceTitle" type="success" :closable="false" />
    </template>
  </app-page>
</template>

<script>
export default {
  name: 'Record',
  data() {
    return {
      toolbar: {
        size: 'small',
        labelWidth: '80px',
        igrone: ['canteenType'],
        model: {
          name: undefined,
          departmentId: undefined,
          telephone: undefined,
          policeCard: undefined,
          consumerGroupId: undefined,
          canteenType: Number(Number(sessionStorage.getItem('canteenType')) !== 999 ? sessionStorage.getItem('canteenType') : 1),
          startDate: '',
          endDate: '',
          type: undefined,
          operateUserId: undefined
        },
        fields: [
          {
            label: '姓名',
            prop: 'name',
            placeholder: '请输入要搜索的姓名',
            clearable: true
          },
          {
            label: '部门',
            prop: 'departmentId',
            placeholder: '请选择要搜索的部门',
            type: 'cascader',
            clearable: true,
            collapseTags: true,
            filterable: true,
            props: {
              expandTrigger: 'hover',
              multiple: true,
              label: 'name',
              value: 'id'
            },
            options: [],
            url: '/department/getList',
            method: 'post'
          },
          {
            label: '电话',
            prop: 'telephone',
            placeholder: '请输入要搜索的电话',
            clearable: true
          },
          {
            label: '警号',
            prop: 'policeCard',
            placeholder: '请输入要搜索警号',
            clearable: true
          },
          {
            label: '分组',
            prop: 'consumerGroupId',
            placeholder: '请选择要搜索分组',
            type: 'select',
            clearable: true,
            options: [],
            url: '/canteenConsumerGroup/dropDownList',
            mapper(item) {
              return {
                label: item.name,
                value: item.id
              };
            }
          },
          {
            label: '食堂',
            prop: 'canteenType',
            placeholder: '请选择要搜索食堂',
            type: 'select',
            // clearable: true,
            options: [
              { label: '全部', value: undefined },
              { label: '一食堂', value: 1 },
              { label: '二食堂', value: 2 }
            ]
          },
          {
            label: '查询日期',
            prop1: 'startDate',
            prop2: 'endDate',
            type: 'daterange',
            valueFormat: 'yyyy-MM-dd',
            format: 'yyyy-MM-dd',
            startPlaceholder: '开始日期',
            endPlaceholder: '结束日期',
            pickerOptions: {
              firstDayOfWeek: 1
            }
          },
          {
            label: '类型',
            prop: 'type',
            placeholder: '请选择要搜索的类型',
            type: 'select',
            clearable: true,
            options: [
              { label: '全部', value: undefined },
              { label: '补贴扣费', value: 0 },
              { label: '现金扣费', value: 1 },
              { label: '提现扣费', value: 2 }
            ]
          },
          {
            label: '操作人',
            prop: 'operateUserId',
            type: 'select',
            clearable: true,
            options: [],
            url: '/admin-user/getList',
            method: 'post',
            data: {
              startPage: 1,
              pageSize: 500,
              isReg: 1
            },
            mapper(item) {
              return {
                label: item.name,
                value: item.userid
              };
            },
            returnKey: 'records'
          }
        ],
        downloader: {
          text: '导出',
          url: '/canteenManualDeductionLog/exportExcel'
        }
      },
      list: {
        url: '/canteenManualDeductionLog',
        fields: [
          {
            label: '姓名',
            prop: 'accountUserName',
            width: 120,
            headerAlign: 'center',
            showOverflowTooltip: true
          },
          {
            label: '电话',
            prop: 'telephone',
            width: 160,
            headerAlign: 'center'
          },
          {
            label: '警号',
            prop: 'accountUserPoliceCard',
            width: 120,
            headerAlign: 'center'
          },
          {
            label: '扣费时间',
            prop: 'createTime',
            headerAlign: 'center',
            width: 220
          },
          {
            label: '扣费类型',
            prop: 'type',
            width: 120,
            headerAlign: 'center',
            align: 'center',
            colors: ['danger', 'warning', 'primary'],
            formatter(row) {
              const values = ['补贴扣费', '现金扣费', '提现扣费'];
              return values[row.type];
            }
          },
          {
            label: '扣费金额',
            prop: 'amount',
            width: 120,
            headerAlign: 'center',
            align: 'right',
            color: 'danger',
            formatter(row) {
              return `￥${row.amount}`;
            }
          },
          {
            label: '补贴余额',
            prop: 'subsidySurplus',
            width: 120,
            headerAlign: 'center',
            align: 'right',
            formatter(row) {
              return `￥${row.subsidySurplus}`;
            }
          },
          {
            label: '现金余额',
            prop: 'cashSurplus',
            width: 120,
            headerAlign: 'center',
            align: 'right',
            formatter(row) {
              return `￥${row.cashSurplus}`;
            }
          },
          {
            label: '备注',
            prop: 'remark',
            showOverflowTooltip: true,
            headerAlign: 'center',
            align: 'left'
          },
          {
            label: '操作人',
            prop: 'operateUserName',
            width: 140,
            headerAlign: 'center',
            align: 'right'
          }
        ],
        action: false
      },
      balance: {
        subsidyMoneyTotal: 0,
        balanceTotal: 0,
        withdrawTotal: 0
      }
    };
  },
  computed: {
    // 余额提示栏标题
    balanceTitle() {
      const { subsidyMoneyTotal, balanceTotal, withdrawTotal } = this.balance;
      return `补贴扣费总计:￥${subsidyMoneyTotal}，现金扣费总计:￥${balanceTotal}，提现扣费总计:￥${withdrawTotal}`;
    }
  },
  methods: {
    onLoaded(e) {
      this.balance = e.other;
      if (sessionStorage.getItem('canteenType')){
        if (Number(sessionStorage.getItem('canteenType')) === 1){
          this.toolbar.fields[5].options = [{ label: '一食堂', value: 1 }]
        }else if (Number(sessionStorage.getItem('canteenType')) === 2){
          this.toolbar.fields[5].options = [{ label: '二食堂', value: 2 }]
        }
      }
    }
  }
};
</script>
